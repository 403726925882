@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /*background-image:url('/src/images/pastel.jpg'); */
  background-color: rgb(248, 246, 253);
  text-align: center;
}

body,
input,
textarea,
button {
  font-family: "Inter", sans-serif;
}

.app {
  width: 400px;
  margin: 0 auto;
  height: 100vh;
}

/* Contact.js */
.form {
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.form > h1 {
  margin-bottom: 50px;
}

.form > label {
  margin-left:100px;
  margin-right: 100px;
}

.form > input,
 textarea {
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137); 
  margin-bottom: 20px;
  margin-left: 100px;
  margin-right: 100px;
  border: 1px solid lightgray;
  background: #fff;
  font-size: 16px;
  color: rgb(0, 0, 32);
  outline: none;
} 

.form > input {
  display: flex;
  height: 10px;
  max-width: 60000px;
  min-height: 10px;     
}



.form > input:focus,
textarea:focus {
  border: 1px solid rgb(224, 193, 130);
}

 .form > textarea {
  display: flex;
  height: 150px;
  padding-left: 50px;
  padding-right: 50px;
  min-height: 50px;
  resize: none;
}
 


.form > label {
  padding-bottom: 20px;
  color: rgb(0, 0, 32);
  font-weight: bold;
}

 .form > button {
  background-color: rgb(224, 193, 130);
  font-weight: 500;
  font-size: 20px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  
}  

.form > button:hover {
  background-color: rgb(224, 193, 130);
}


.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer; 
}

.btn--primary {
  background-color: rgb(224, 193, 130);
  color: #242424;
  border: 1px solid #fff;
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding:8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.5s ease-out; 
}

.btn--medium {
  padding:8px 20px;
  font-size: 20px;
}

.btn--large {
  padding:12px 26px;
  font-size: 20px;
}

.btn--medium:hover, .btn--large:hover {
  background: #fff;
  color: #242424;
  transition: 250ms;
}

root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
  transition: 250ms;
}
 .btn--medium1
 {
   margin-left: 100px !important ;
   margin-right: 100px !important;
 }